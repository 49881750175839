















































import { Component, Vue } from 'vue-property-decorator';
import { GreyBox } from '@/components';
import BrandStore from '@/store/modules/brand';
import UserStore from '@/store/modules/user';
import { BrandDocument, UserDocument, RawVideoExportLogModel, RawVideoExportLogDocument } from '@/models';
import { Helper } from '@/util';

@Component({
  name: 'Prod1RawVideoExportList',
  components: {
    GreyBox,
  },
})
export default class extends Vue {
  private isSearching: boolean = false;
  private results: RawVideoExportLogDocument[] = [];
  private resultSlice: RawVideoExportLogDocument[] = [];
  private resultSliceSize: number = 10;
  private totalResults: number = 0;
  private brands: BrandDocument[] = (undefined as unknown) as BrandDocument[];
  private rawExport: RawVideoExportLogDocument = RawVideoExportLogModel.create();
  private rules = Helper.schemaToRules(RawVideoExportLogModel.getSchema());

  private created() {
    this.brands = BrandStore.list;
    this.rawExport.brandList = this.brands.map(x => x.id);
  }

  private mounted() {
    // this.doSearch();
  }

  private async doSearch(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;

    this.results = await RawVideoExportLogModel.find();

    this.totalResults = this.results.length;
    this.setPagination(1);

    this.isSearching = false;
  }

  private setPagination(newPage: number): void {
    const startPos: number = Math.max(0, this.resultSliceSize * (newPage - 1));

    this.resultSlice = this.results.slice(startPos, startPos + this.resultSliceSize);
  }

  private async createExport(): Promise<void> {
    const user: UserDocument = UserStore.current as UserDocument;

    this.rawExport.emailSetup = {
      email: user.email,
      name: user.name,
    };

    await this.rawExport.compileVideoSetup();

    if (this.rawExport.compiled.length === 0) {
      this.$alert('Could not find any videos with your search criteria.', 'Empty Raw Video Export', { type: 'error' });

      return;
    }

    await this.rawExport.save();

    this.$alert(
      `A total of ${this.rawExport.compiled.length} video(s) are being prepared. A download link will be emailed to you shortly.`,
      'Raw Video Export',
      { type: 'success' },
    );

    this.rawExport = RawVideoExportLogModel.create();
    // await this.doSearch();
  }
}
